import Cookies from 'universal-cookie'

const cookie = new Cookies();

const TokenService = {

    // Création du cookie contenant la langue souhaitée
    setLanguageToken(language){
        cookie.set('lang', language);
    },

    // Récupération de la langue souhaitée via le cookie
    getLanguageToken(){
        return cookie.get('lang');
    },
    
}

export default TokenService;