import ApiBeetween from './ApiBeetween';
import ApiSofitex from './ApiSofitex';

const AxiosCenter = {

    postCandidature(data){
        return ApiBeetween({
            method: 'post',
            data: data
        });
    },

    getListeOffres(){
        return ApiSofitex({
            method: 'get',
            url: '/annonceclient/giolu1',
        });
    },

    getAnnonce(id){
        return ApiSofitex({
            method: 'get',
            url: '/annonce/'+id,
        });
    },
}

export default AxiosCenter;