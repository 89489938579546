import { Route, Routes } from "react-router-dom";
import RouteNotFound from "./RouteNotFound";
import Index from "../component/Index";
import Detail from "../component/Detail";

function Routing() {

    return (
        <Routes>
            <Route exact path="/" element={<Index />} />
            <Route exact path="/detail" element={<Detail />} />

            {/* Route not found */}
            <Route component={RouteNotFound} />
        </Routes>
    )
}
export default Routing;