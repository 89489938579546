import '../Global.css';
import React, { Component } from 'react';
import { Box, Grid, GridItem, Image, HStack, Flex, Button, Text, VisuallyHidden, Center, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, FormControl, FormErrorMessage, Input, Checkbox, Spinner, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import { FaLinkedinIn, FaFacebookF } from 'react-icons/fa';
import { RiMailSendLine } from 'react-icons/ri';
import { CgArrowRightR } from 'react-icons/cg';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { IoMail } from 'react-icons/io5';
import { AiOutlineNumber } from 'react-icons/ai';
import { BsTelephoneFill, BsGeoAltFill, BsClock, BsGeoAlt, BsFileText } from 'react-icons/bs';
import Select from 'react-select';
import Popup from "reactjs-popup";

import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import AxiosCenter from '../axios/AxiosCenter';
import Success from '../axios/toasts/Success';
import Error from '../axios/toasts/Error';
import TranslationContainer from '../languages/Translation/TranslationContainer';
import TokenService from '../axios/TokenService';
import LangSwitchContainer from '../languages/LangSwitch/LangSwitchContainer';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

import Logo1 from '../images/logo_sofitex.svg';
import FlagPT from '../images/por-flag.png';
import FlagFR from '../images/fr-flag.png';
import FlagLU from '../images/lux-flag.png';
import FlagCH from '../images/ch-flag.png';
import FlagBE from '../images/flag-be.png';
import FlagAL from '../images/all-flag.png';

export default class Detail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      file: '',
      candidatureLoading: false,
      reinitialize: false,
      rgpd: false,
      modalIsOpen: false,
      offres: [],
      annonce: {},
      isLoaded: false,
      video: '',
      modalLanguage: false,
    };
  }

  sendCandidature(values) {
    this.setState({
      candidatureLoading: true
    })

    var input = document.querySelector('input[type="file"]')
    var data = new FormData()
    data.append('data', input.files[0])
    data.append('wid', values.poste.value)
    data.append('firstname', values.prenom)
    data.append('lastname', values.nom)
    data.append('email', values.email)
    data.append('filenames', input.files[0].name)

    AxiosCenter.postCandidature(data)
    .then((response) => {
      Success("Candidature envoyée !")
      this.setState({
        candidatureLoading: false,
        reinitialize: !this.state.reinitialize,
      })
    })
    .catch((error) => {
      Error("Une erreur est survenue !")
      this.setState({
        candidatureLoading: false
      })
    })
  }

  handleResetForm() {
    this.setState({
      reinitialize: !this.state.reinitialize,
    })
  }

  // Ouverture du modal de contact
  handleToggle() {
    this.setState({
        modalIsOpen: true,
    })
  }

  // Fermeture du modal de contact
  handleClose() {
      this.setState({
          modalIsOpen: false,
      })
  }

  openModalLanguage() {
    this.setState({
      modalLanguage: true
    })
  }

  closeModalLanguage() {
    this.setState({
      modalLanguage: false
    })
  }

  componentDidMount() {
    window.scrollTo(0,0)
    let id = window.location.search.split("id=")[1]
    this.setState({id})
    AxiosCenter.getAnnonce(id)
    .then((response) => {
      console.log(response.data)
      this.setState({
          annonce: response.data,
          video: response.data.videoURL,
          isLoaded: true,
      })
    })
    .catch((error) => {
      Error("Une erreur est survenue !")
      this.setState({
        candidatureLoading: false
      })
    })
  }

  render() {

    const { reinitialize, annonce, video, isLoaded, modalLanguage} = this.state;

    const candidatureSchema = yup.object().shape({
      nom: yup
      .string()
      .label('nom')
      .required(<TranslationContainer translationKey="errorMandatoryName"/>),
      prenom: yup
      .string()
      .label('prenom')
      .required(<TranslationContainer translationKey="errorMandatoryFirstName"/>),
      email: yup
      .string()
      .label('email')
      .matches(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)/, <TranslationContainer translationKey="errorInvalidEmail"/>)
      .required(<TranslationContainer translationKey="errorMandatoryEmail"/>),
      phone: yup
      .string()
      .label('phone')
      .required(<TranslationContainer translationKey="errorMandatoryTelephone"/>),
      // .matches(/^((\+)33|0|0033)[1-9](\d{2}){4}$/g, "Numéro de téléphone invalide"),
      cv: yup
      .string()
      .label('cv')
      .required(<TranslationContainer translationKey="errorMandatoryCV"/>),
      poste: yup
      .object()
      .label('poste')
      .required(<TranslationContainer translationKey="errorMandatoryjob"/>),
      rgpd: yup
      .string()
      .label('rgpd')
      .matches(true, () => <TranslationContainer translationKey="errorMandatoryRGPD"/>),
    });

    const posteOptions = [
        {value: this.state.annonce.id, label: this.state.annonce.title},
      // {value: "wh1e6r55g4", label: "Agent de fabrication junior (H/F)"}
    ]

    // Style du select
    const selectFlushedStyle = {       
      valueContainer: (base, state) => ({
        ...base,
        paddingLeft: 0,
      }),
      placeholder: (base, state) => ({
        ...base,
        color: "black",
        marginLeft: 0,
      }),
      indicatorSeparator: (base, state) => ({
        ...base,
        backgroundColor: 'white',
      }),
      control: (base, state) => ({
        ...base,
        borderRadius: 'none',
        borderRight: "none",
        borderLeft: "none",
        borderTop: "none",
        borderBottom: state.isFocused ? "2px solid #00de00" : "1px solid #e2e8f0",
        boxShadow: "none",
        '&:hover': {
          //   borderBottom: "2px solid #00de00",
            boxShadow: "none"
        },
      }),
      singleValue: (base, state) => ({
        ...base,
        fontWeight: "bold",
      }),
      multiValueLabel: (base, state) => ({
        ...base,
        fontWeight: "bold"
      }),
      option: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? "rgba(3, 252, 0, 0.2)" : "white",
        color: "black",
        '&:active': {
          backgroundColor: "rgba(3, 252, 0, 0.4)",
        }
      }),
    }
    
    const Menu = ({ close }) => (
      <div className="menu">
        <ul onClick={close}>
          <Link to="/">
            <li className='sofitex-menu-link'><TranslationContainer translationKey="backHome"/></li>
          </Link>
          <a href={"#candidature"}>
            <li className='sofitex-menu-link'><TranslationContainer translationKey="applyMenu"/></li>
          </a>
          <a href={"#contact"}>
            <li className='sofitex-menu-link'><TranslationContainer translationKey="contactMenu"/></li>
          </a>
          <li className='sofitex-menu-link' onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-sofitex-industrie-btp.html")}><TranslationContainer translationKey="otherOffersMenu"/></li>
        </ul>
      </div>
    );
  
    const monthName = [
      <TranslationContainer translationKey="jan"/>,
      <TranslationContainer translationKey="fev"/>,
      <TranslationContainer translationKey="mar"/>,
      <TranslationContainer translationKey="avr"/>,
      <TranslationContainer translationKey="mai"/>,
      <TranslationContainer translationKey="juin"/>,
      <TranslationContainer translationKey="juil"/>,
      <TranslationContainer translationKey="aout"/>,
      <TranslationContainer translationKey="sept"/>,
      <TranslationContainer translationKey="oct"/>,
      <TranslationContainer translationKey="nov"/>,
      <TranslationContainer translationKey="dec"/>
    ]

    return (
      <Box pt={{base: "54px", lg: "70px"}}>
        <Box width="100%" position={'fixed'} top={0} zIndex={99} backgroundColor="#2f2f2f" mb={{base: "40px", lg: "60px"}}>
          <Flex width={{base:"100%", lg: "1024px", xl: "1250px"}} alignItems={'center'} mx={'auto'}>
            <HStack height={{base: "54px", lg: "70px"}} pr={{base: 2, md: 4}} mr={'auto'}>
              <Box height="100%" mr="auto">
                <Image src={Logo1} alt="logo mysofitex" width={{base: "130px", lg:"200px"}} position="relative" top={{base: "25px", lg: "28px"}} left={{base: "9px", lg: "35px"}}/>
              </Box>
            </HStack>
            <Box display={{base: "none", md: "flex"}} pr={10}>
              <Link to="/">
                <Flex pl={5} className='sofitex-menu-link' as="button" alignItems="center">
                    <IoMdArrowRoundBack size="1.2em" />
                    <Text className='font-sofitex' ml={2}><TranslationContainer translationKey="backHome"/></Text>
                </Flex>
              </Link>
            </Box>
            <Box as={'button'} mr={{base: 0, md: 4}} position={{base: 'absolute', md: 'unset'}} top={'9px'} right={'60px'} onClick={() => this.openModalLanguage()}>
              {TokenService.getLanguageToken() === 'pt_BR' ?
              <Flex alignItems={'center'} columnGap={1}>
                <Image width="37px" alt='' src={FlagPT} />
                <Text color={'white'} fontSize={'xs'}>português</Text>
              </Flex>
              :
              (!TokenService.getLanguageToken() || TokenService.getLanguageToken() === 'fr_BE') &&
              <Flex alignItems={'center'} columnGap={1}>
                <Image width="37px" alt='' src={FlagFR} />
                <Text color={'white'} fontSize={'xs'}>français</Text>
              </Flex>
            }
            </Box>
          </Flex>
        </Box>
        <VisuallyHidden>Félix Giorgetti - </VisuallyHidden>
        <VisuallyHidden><TranslationContainer translationKey="visuallyHidden1"/></VisuallyHidden>
        <VisuallyHidden><TranslationContainer translationKey="visuallyHidden2"/></VisuallyHidden>
        <VisuallyHidden><TranslationContainer translationKey="visuallyHidden3"/></VisuallyHidden>
        <VisuallyHidden><TranslationContainer translationKey="visuallyHidden4"/></VisuallyHidden>
        {!isLoaded ?
        <Center height="500px">
          <Spinner color='#00ed00' size="lg" />
        </Center>
        :
        <>
        <Grid mt={{base: "54px", lg: "70px"}} templateColumns="repeat(12, 1fr)" mb={{lg: 5}}>
          <GridItem colSpan={{base: 12, lg: 10, xl: 8}} colStart={{base: 1, lg: 2, xl: 3}}>
            <Text className='font-sofitex' ml={{base: 3, md: 5}} fontSize="4xl" fontWeight="bold">{annonce.title}</Text>
            <Text className='font-sofitex' ml={{base: 3, md: 5}} fontSize="sm" color="gray.500" fontStyle="italic"><TranslationContainer translationKey="published"/> {new Date(annonce.last_modification_date).getDate()} {monthName[new Date(annonce.last_modification_date).getMonth()]} {new Date(annonce.last_modification_date).getFullYear()}</Text>
            <Grid templateColumns="repeat(4, 1fr)" mt={6} mb={10} borderTop="1px solid lightgray" borderBottom="1px solid lightgray">
                <GridItem colSpan={{base: 4, md: 2, xl: 1}}>
                    <Flex my={4} pl={6} alignItems="center" width="100%">
                        <AiOutlineNumber color='gray' />
                        <Box className='font-sofitex' ml={4}>
                            <Text fontSize={{base: "md", lg: "sm"}} color="#00de00"><TranslationContainer translationKey="reference"/></Text>
                            <Text fontSize="lg">{annonce.id}</Text>
                        </Box>
                    </Flex>
                </GridItem>
                <GridItem colSpan={{base: 4, md: 2, xl: 1}}>
                    <Flex my={4} pl={6} alignItems="center" width="100%">
                        <BsGeoAlt color='gray' />
                        <Box className='font-sofitex' ml={4}>
                            <Text fontSize={{base: "md", lg: "sm"}} color="#00de00"><TranslationContainer translationKey="location"/></Text>
                            <Text fontSize="lg">{annonce.display}</Text>
                        </Box>
                    </Flex>
                </GridItem>
                <GridItem colSpan={{base: 4, md: 2, xl: 1}}>
                    <Flex my={4} pl={6} pr={2} alignItems="center" width="100%">
                        <Box className='font-sofitex' minWidth="16px">
                            <BsFileText color='gray' />
                        </Box>
                        <Box ml={4}>
                            <Text fontSize={{base: "md", lg: "sm"}} color="#00de00"><TranslationContainer translationKey="domain"/></Text>
                            <Text fontSize="lg">{annonce.domaine}</Text>
                        </Box>
                    </Flex>
                </GridItem>
                <GridItem colSpan={{base: 4, md: 2, xl: 1}}>
                    <Flex my={4} pl={6} alignItems="center" width="100%">
                        <BsClock color='gray' />
                        <Box className='font-sofitex' ml={4}>
                            <Text fontSize={{base: "md", lg: "sm"}} color="#00de00"><TranslationContainer translationKey="type"/></Text>
                            <Text fontSize="lg">{annonce.type}</Text>
                        </Box>
                    </Flex>
                </GridItem>
            </Grid>
            <Accordion className="font-sofitex" defaultIndex={[0,1,2]} allowMultiple width="100%">
              <AccordionItem>
                <h2>
                  <AccordionButton backgroundColor="#f3f5f3">
                    <Box ml={1} flex='1' textAlign='start' fontSize="2xl" fontWeight="bold" className='font-sofitex description-title'>
                      <TranslationContainer translationKey="companyDescription"/>
                    </Box>
                    <AccordionIcon color="#00ed00" />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={10} textAlign='start'>
                  <Box ml={2} mt={2} fontSize={'lg'} className='font-sofitex' dangerouslySetInnerHTML={{ __html: annonce.company }}>                    
                  </Box>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton backgroundColor="#f3f5f3">
                    <Box ml={1} flex='1' textAlign='start' fontSize="2xl" fontWeight="bold" className='font-sofitex description-title'>
                      <TranslationContainer translationKey="jobDescription"/>
                    </Box>
                    <AccordionIcon color="#00ed00" />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={10} textAlign='start' backgroundColor="white">
                  <Box ml={2} mt={2} fontSize={'lg'} className='font-sofitex' dangerouslySetInnerHTML={{ __html: annonce.mission }}>
                  </Box>
                  {video !== "" &&
                  <Box mt={5} maxWidth={'100vw'}>
                    <ReactPlayer url={video} width='100%'/>
                  </Box>
                  }
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton backgroundColor="#f3f5f3">
                    <Box ml={1} flex='1' textAlign='start' fontSize="2xl" fontWeight="bold" className='font-sofitex description-title'>
                      <TranslationContainer translationKey="profile"/>
                    </Box>
                    <AccordionIcon color="#00ed00" />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={10} textAlign='start'>
                  <Box ml={2} mt={2} fontSize={'lg'} dangerouslySetInnerHTML={{ __html: annonce.profile }}>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </GridItem>
        </Grid>
        <Grid id="candidature" mb={{base: 4, md: 8}} templateColumns="repeat(12, 1fr)">
          <GridItem py={4} px={{base: 4, md: 2}} rounded="lg" colSpan={{base: 12, lg: 10, xl: 8}} colStart={{base: 1, lg: 2, xl: 3}}>
            <Flex mb={4} alignItems="center">
              <CgArrowRightR color="#00ed00" size="1.4em" />
              <Text className="font-sofitex" ml={1} fontSize="2xl" fontWeight="bold" color="#00ed00"><TranslationContainer translationKey="applicationTitle"/></Text>
            </Flex>
            <Formik
              initialValues = {{poste: posteOptions[0], nom: '', prenom: '', email: '', phone: '', cv: '', rgpd: false, reinitialize: reinitialize}}
              validationSchema = {candidatureSchema} 
              onSubmit = {(values) => this.sendCandidature(values)}
              enableReinitialize
            >
              {formikProps => (
                  <Form noValidate>
                    <Grid templateColumns="repeat(2, 1fr)" columnGap={5} mb={3}>
                      <GridItem colSpan={{base: 2, sm: 1}}>
                        <FormControl isInvalid={formikProps.errors.poste && formikProps.touched.poste} mb={5}>
                            <Text className="font-sofitex" mb={0} fontWeight="normal" fontSize="sm" color="gray.400"><TranslationContainer translationKey="jobLabel"/></Text>
                            <Select
                              className="select-input"
                              id="poste"
                              name="poste"
                              isSearchable={false}
                              placeholder={<TranslationContainer translationKey="jobPlaceholder"/>}
                              options={posteOptions}  
                              styles={selectFlushedStyle}
                              value={formikProps.values.poste} 
                              onChange={selectedOptions => formikProps.setFieldValue('poste', selectedOptions === null ? formikProps.values.poste = '' :  selectedOptions)}
                            />
                            <FormErrorMessage className="font-sofitex ml-1">{formikProps.errors.poste}</FormErrorMessage>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <Grid templateColumns="repeat(2, 1fr)" columnGap={5} rowGap={5}>
                      <GridItem colSpan={{base: 2, sm: 1}}>
                        <FormControl isInvalid={formikProps.errors.nom && formikProps.touched.nom} mb={5}>
                            <Text className="font-sofitex" mb={0} fontWeight="normal" fontSize="sm" color="gray.400"><TranslationContainer translationKey="nameLabel"/></Text>
                            <Field
                              id='nom'
                              name="nom"
                              className="font-sofitex"
                            >
                                {({field, form, meta}) => 
                                <Input 
                                  {...field} 
                                  type="text" 
                                  focusBorderColor="#00ed00"
                                  size="sm"
                                  variant="flushed"
                                  width="100%"
                                  onChange={formikProps.handleChange("nom")}
                                />
                                }
                            </Field>
                            <FormErrorMessage className="font-sofitex ml-1">{formikProps.errors.nom}</FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={{base: 2, sm: 1}}>
                        <FormControl isInvalid={formikProps.errors.prenom && formikProps.touched.prenom} mb={5}>
                          <Text className="font-sofitex" mb={0} fontWeight="normal" fontSize="sm" color="gray.400"><TranslationContainer translationKey="firstNameLabel"/></Text>
                          <Field
                              id='prenom'
                              name="prenom"
                          >
                              {({field, form, meta}) => 
                              <Input 
                                  {...field} 
                                  type="text" 
                                  focusBorderColor="#00ed00"
                                  size="sm"
                                  variant="flushed"
                                  width="100%"
                                  onChange={e => {formikProps.handleChange(e)}}
                              />
                              }
                          </Field>
                          <FormErrorMessage className="font-sofitex ml-1">{formikProps.errors.prenom}</FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={{base: 2, sm: 1}}>
                        <FormControl isInvalid={formikProps.errors.email && formikProps.touched.email} mb={8}>
                            <Text className="font-sofitex" mb={0} fontWeight="normal" fontSize="sm" color="gray.400"><TranslationContainer translationKey="emailLabel"/></Text>
                            <Field
                                id='email'
                                name="email"
                            >
                            {({field, form, meta}) => 
                                <Input 
                                {...field} 
                                type="email" 
                                focusBorderColor="#00ed00"
                                size="sm"
                                variant="flushed"
                                width="100%"
                                onChange={e => {formikProps.handleChange(e)}}
                            />
                            }
                            </Field>
                            <FormErrorMessage className="font-sofitex ml-1">{formikProps.errors.email}</FormErrorMessage>
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={{base: 2, sm: 1}}>
                        <FormControl isInvalid={formikProps.errors.phone && formikProps.touched.phone} mb={8}>
                            <Text className="font-sofitex" mb={0} fontWeight="normal" fontSize="sm" color="gray.400"><TranslationContainer translationKey="phoneLabel"/></Text>
                            <Field
                                id='phone'
                                name="phone"
                            >
                            {({field, form, meta}) => 
                                <Input 
                                {...field} 
                                type="tel" 
                                focusBorderColor="#00ed00"
                                size="sm"
                                variant="flushed"
                                width="100%"
                                onChange={e => {formikProps.handleChange(e)}}
                            />
                            }
                            </Field>
                            <FormErrorMessage className="font-sofitex ml-1">{formikProps.errors.phone}</FormErrorMessage>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <Grid templateColumns="repeat(2, 1fr)" columnGap={5} mb={3}>
                      <GridItem colSpan={{base: 2, sm: 1}}>
                        <FormControl isInvalid={formikProps.errors.cv && formikProps.touched.cv}>
                            <Text className="font-sofitex" mb={1} fontWeight="normal" fontSize="sm" color="gray.400"><TranslationContainer translationKey="cvLabel"/></Text>
                            <Field
                                id='cv'
                                name="cv"
                            >
                            {({field, form, meta}) => 
                              <input
                                {...field}
                                className='font-sofitex'
                                type="file"
                                accept=".pdf,.docx,.doc,.jpg,.jpeg,.png"
                                onChange={(event) => {formikProps.handleChange(event)}}
                              />
                            }
                            </Field>
                            <FormErrorMessage className="font-sofitex ml-1">{formikProps.errors.cv}</FormErrorMessage>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <Grid templateColumns="repeat(2, 1fr)" mb={3} mt={8}>
                      <GridItem colSpan={2}>
                        <FormControl isInvalid={formikProps.errors.rgpd && formikProps.touched.rgpd}>
                            <Checkbox
                              className="font-sofitex"
                              colorScheme="sofitex"
                              name="rgpd"
                              defaultChecked={formikProps.values.rgpd}
                              isChecked={formikProps.values.rgpd}
                              value={formikProps.values.rgpd}
                              onChange={formikProps.handleChange('rgpd')}
                            >
                              <Box className="font-sofitex" as="span" fontSize="sm"><TranslationContainer translationKey="rgpdText"/></Box>&nbsp;<a className="sofitex-link font-sofitex" style={{ color: "#03b403"}} target="_blank" rel="noreferrer" href="https://www.sofitex.fr/FR/politique-de-confidentialite.html"><TranslationContainer translationKey="rgpdLink"/></a>  
                            </Checkbox>
                            <FormErrorMessage className="font-style ml-1">{formikProps.errors.rgpd}</FormErrorMessage>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <Flex justifyContent="center" width="100%s">
                      <Button className="button font-sofitex" mt={6} mx="auto" leftIcon={<RiMailSendLine size="1.2em" />} width={{base: "100%", sm:"auto"}} isLoading={this.state.candidatureLoading} type="submit"><TranslationContainer translationKey="sendBtn"/></Button>                
                    </Flex>
                  </Form>                       
              )}
            </Formik>
          </GridItem>
        </Grid>
        <Grid id="contact" templateColumns="repeat(12, 1fr)">
          <GridItem colSpan={{base: 12, lg: 10, xl: 8}} colStart={{base: 1, lg: 2, xl: 3}} mb={{base: 0, lg: 10}} borderTop={{base: "1px solid lightgray", lg: "none"}}>
            <Box id="contact" py={6} px={{base: 4, md: 8}} mb={{base: 0, lg: 10}} backgroundColor="gray.100" rounded={{base: 'none', lg: 'md'}} border={'1px solid #e2e8f0'}>
              <Text className='font-sofitex' fontWeight="bold" fontStyle="italic" fontSize="2xl" textAlign={{base: "center", lg: "start"}}><TranslationContainer translationKey="contactBoxTitle"/></Text>
              <Box className='font-sofitex' mt={{base: 2, md: 2}}>
                <Text mb={2} fontSize="lg" fontWeight="bold">SOFITEX INTÉRIM ESCH-SUR-ALZETTE&nbsp;-&nbsp;BTP</Text>
                <Flex mb={2} alignItems="center">
                  <BsGeoAltFill size="1.1em" />
                  <Text ml={2}>5, rue Luxembourg,</Text>
                  <Text ml={2}>L-4220 Esch-sur-Alzette</Text>
                </Flex>
                <Flex mb={2} alignItems="center">
                  <BsTelephoneFill />
                  <a className="sofitex-contact-link" href='tel:+352547633'><Text ml={2}>+352 (0)54 76 33</Text></a>
                </Flex>
                <Flex alignItems="center">
                  <IoMail size="1.1em" />
                  <a className="sofitex-contact-link" href='mailto:esch@sofitex.lu'><Text ml={2}>esch@sofitex.lu</Text></a>
                </Flex>
              </Box>
            </Box>
          </GridItem>
        </Grid>
        </>
        }
        <Grid templateColumns="repeat(12, 1fr)" mt={{base: 0, lg: 0}}>
        <GridItem colSpan={{base: 12, md: 8, lg: 9}} backgroundColor="#0a0a0a" py={{base: "40px", lg: "50px"}} px={{base: 2, md: 7}}>
          <Text className="font-sofitex" fontSize="lg" color="#00ed00" textAlign={{base:"center", md: "start"}}><TranslationContainer translationKey="footerTitle1"/></Text>
          <Text className="font-sofitex" fontSize="sm" color="#03b403" textAlign={{base:"center", md: "start"}}><TranslationContainer translationKey="footerTitle2"/></Text>
          <Grid templateColumns="repeat(6, 1fr)" mt={3}>
            <GridItem colSpan={{base: 3, lg: 2}}>
              <Flex justifyContent={{base:"center", md: "flex-start"}}>
                <Box as="button" className="sofitex-link" onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-sofitex-industrie-btp.html")}>Esch-sur-Alzette-BTP</Box>
              </Flex>
              <Flex justifyContent={{base:"center", md: "flex-start"}}>
                <Box as="button" className="sofitex-link" onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-esch-sur-alzette-industrie.html")}>Esch-sur-Alzette</Box>
              </Flex>
              <Box width="100%" display={{base: "block", lg: "none"}}>
                <Flex justifyContent={{base:"center", md: "flex-start"}}>
                  <Box as="button" className="sofitex-link" onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-sofitex-wiltz.html")}>Wiltz</Box>
                </Flex>
              </Box>
            </GridItem>
            <GridItem colSpan={{base: 3, lg: 2}}>
              <Flex justifyContent={{base:"center", md: "flex-start"}}>
                <Box as="button" className="sofitex-link" onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-sofitex-tertiaire-banque-assurance-luxembourg.html")}>Luxembourg-Ville</Box>
              </Flex>
              <Flex justifyContent={{base:"center", md: "flex-start"}}>
                <Box as="button" className="sofitex-link" textAlign={{base: "center", md: "start"}} onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-sofitex-talent.html")}>Talent - Finance, Office, IT</Box>
              </Flex>
            </GridItem>
            <GridItem display={{base: "none", lg: "block"}} colSpan={2}>
              <Flex justifyContent={{base:"center", md: "flex-start"}}>
                <Box as="button" className="sofitex-link" onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-sofitex-wiltz.html")}>Wiltz</Box>
              </Flex>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem colSpan={{base: 12, md: 4, lg: 3}} px={2} backgroundColor="#00ed00">
          <Flex height="100%" alignItems="center" py={{base: "40px", lg: 0}}>
            <Box width="100%">
              <Text className="font-sofitex" mb={2} textAlign="center" fontWeight="bold"><TranslationContainer translationKey="footerJob"/>&nbsp;:</Text>
              <Flex justifyContent="center" mt={2}>
                <Box as="button" mr={3} onClick={() => window.open("https://www.facebook.com/sofitex.luxembourg")}>
                  <FaFacebookF size="1.8em" />
                </Box>
                <Box as="button" pt={1} onClick={() => window.open("https://www.linkedin.com/company/sofitex-luxembourg")}>
                  <FaLinkedinIn size="2.1em" />
                </Box>
              </Flex>
            </Box>
          </Flex>
        </GridItem>
      </Grid>
      <Box maxWidth="100vw" py={{base: "30px", md: "50px"}} px={{base: 2, md: "20%"}} backgroundColor="#2f2f2f">
        <Box display={{base: "none", lg: "block"}}>
          <Text className="font-sofitex" mb={4} textAlign="center" fontSize="sm" color="#b5b5b5">
            <TranslationContainer translationKey="footerText1"/>
          </Text>
        </Box>
        <Text mr={2} textAlign="center" color="white"><TranslationContainer translationKey="footerText2"/>&nbsp;:</Text>
        <Flex mt={1} flexFlow="row wrap" justifyContent="center">
          <Flex>
            <Image width="25px" alt='' src={FlagFR} />
            <Text className="sofitex-link font-sofitex" as="button" ml="2px" mr={2} color="white" onClick={() => window.open("https://www.sofitex.fr/")}>France</Text>
          </Flex>
          <Flex>
            <Image width="25px" alt='' src={FlagLU} />
            <Text className="sofitex-link font-sofitex" as="button" ml="2px" mr={2} color="white" onClick={() => window.open("https://www.sofitex.lu/")}>Luxembourg</Text>
          </Flex>
          <Flex alignItems={'center'}>
            <Image width="25px" alt='' height="15px" src={FlagBE} />
            <Text className="sofitex-link font-sofitex" as="button" ml="2px" mr={2} color="white" onClick={() => window.open("https://www.sofitex.be/")}>Belgique</Text>
          </Flex>
          <Flex>
            <Image width="25px" alt='' src={FlagCH} />
            <Text className="sofitex-link font-sofitex" as="button" ml="2px" mr={2} color="white" onClick={() => window.open("https://www.sofitex.ch/")}>Suisse</Text>
          </Flex>
          <Flex>
            <Image width="25px" alt='' src={FlagAL} />
            <Text className="sofitex-link font-sofitex" as="button" ml="2px" color="white" onClick={() => window.open("https://www.sofitex-zeitarbeit.de/")}>Allemagne</Text>
          </Flex>
        </Flex>
        <Flex mt={3} flexFlow="row wrap" justifyContent="center" alignItems="center">
          <Text className="font-sofitex" mt={1} textAlign="center" color="white"><TranslationContainer translationKey="footerText3"/>&nbsp;:</Text>
          <Flex mt={1}>
            <Text className="sofitex-link font-sofitex" as="button" ml="2px" fontWeight="bold" color="white" onClick={() => window.open("https://www.sofitex-talent.lu/")}>Sofitex Talent</Text>
          </Flex>
        </Flex>
        <Flex justifyContent="center">
          <Text className="sofitex-link font-sofitex" mt={5} textAlign="center" as="button" color="white" onClick={() => window.open("https://www.sofitex.lu/FR/politique-de-confidentialite.html")}><TranslationContainer translationKey="footerPrivacy"/></Text>
        </Flex>
      </Box>

      <Popup
        modal
        overlayStyle={{ background: "#1d1d1d" }}
        closeOnDocumentClick={false}
        trigger={(open) => <Box position={'fixed'} display={{base: "block", md: "none"}} className={open ? "burger-menu open" : "burger-menu"} >
          <div className="bar1"/>
          <div className="bar2"/>
          <div className="bar3"/>
        </Box>}
      >
        {(close) => <Menu close={close} />}
      </Popup>
        {/* Modal choix de la langue */}
        <Modal isOpen={modalLanguage} blockScrollOnMount closeOnOverlayClick size="xs" isCentered onClose={() => this.closeModalLanguage()}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader backgroundColor="#edf2f7" roundedTop="md" borderBottom="1px solid lightgray">
                <Text className="font-style" fontSize="2xl" fontWeight="bold">
                    <TranslationContainer translationKey="languageChoiceTitle"/>
                </Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody onClick={() => this.closeModalLanguage()} py={4}>
                    <LangSwitchContainer />
                </ModalBody>
            </ModalContent>
        </Modal>
      </Box>
    );
  }
}