import Axios from 'axios';
import ApiSofitexConfigUrl from './ApiSofitexConfigUrl';

// Création de l'instance d'Axios + configuration de la base URL des requêtes
const ApiSofitex = Axios.create({
    baseURL:  ApiSofitexConfigUrl,
})

// Interception des requêtes avant envoi pour y insérer l'autorisation avec le jwt dans l'entête
// ApiBeetween.interceptors.request.use(req => {
//     req.headers['Authorization'] = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJoaWRpckBzb2ZpdGV4LmZyIiwiZXhwIjoyMjE4NjEwMjI0LCJqdGkiOiJkcGVsbG00azhoa2RoMDhtNXI4M2NmNDJlIn0.SPKAmZFSShMssoC6vX_jnXQIkAqDloejWeM13YD3inxm9qxboyMd8E0QBE3R0WqhP18vG6-7kJVPe-E9Iilg5w";
//     req.headers['accept'] = "application/json";
//     return req
// })

export default ApiSofitex;