import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import TokenService from '../../axios/TokenService';
import FlagFR from '../../images/fr-flag.png';
import FlagPO from '../../images/por-flag.png';
import { Image } from '@chakra-ui/image';
import { HStack, Box } from '@chakra-ui/layout';

export default class LangSwitch extends Component {

  render() {

    // const flagFra = <Image width="80px" src={FlagFR} />
    // const flagPor = <Image width="80px" src={FlagPO} />

    return (
      <HStack justifyContent="center" spacing={6}>
        <Box as="button" onClick={() => {this.props.setLanguage("fr_BE");TokenService.setLanguageToken("fr_BE");}}>
          <Image width="80px" src={FlagFR} />
        </Box>
        <Box as="button" onClick={() => {this.props.setLanguage("pt_BR");TokenService.setLanguageToken("pt_BR");}}>
          <Image width="80px" src={FlagPO} />
        </Box>
      </HStack>
    );
  }
}

LangSwitch.propTypes = {
  locale: PropTypes.string.isRequired,
  setLanguage: PropTypes.func,
};