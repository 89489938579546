export const TRANSLATIONS = {

  fr_BE: {
    languageChoiceTitle: "Choisir une langue",
    mainTitle: "Construisons l'avenir ensemble.",
    partnershipMenu: "Partenariat Félix Giorgetti - Sofitex",
    applyMenu: "Postuler",
    contactMenu: "Nous contacter",
    otherOffersMenu: "Nos autres offres sur le secteur de Esch-sur-Alzette",
    otherOffers: "Consultez nos autres offres sur le secteur de Esch-sur-Alzette",
    hireFor: "recrute pour",
    visuallyHidden1: " Nos offres d'emploi : ",
    visuallyHidden2: "Coffreur H/F - ",
    visuallyHidden3: "Conducteur d'engins H/F - ",
    visuallyHidden4: "Maçon H/F",
    text1: "Fidèle à notre histoire et à nos racines, Félix Giorgetti est une grande famille composée de plus de 450 membres, et presque 3.000 à l'échelle du groupe. Autant de talents que nous sommes fiers de compter parmi nous et qui font de nous une entreprise reconnue pour la qualité de son travail, pour sa performance et pour sa capacité à innover.",
    text2: "Travailler chez nous, c'est collaborer avec un grand acteur de la construction et de l'immobilier au Luxembourg. Nos collaborateurs sont nos atouts les plus précieux. Leur qualification et leur motivation sont à la base de notre succès.",
    text3: "En tant que société plus que centenaire, l'emploi est fondamental et nous avons toujours eu à coeur de le préserver, même lorsque la conjoncture n'était pas favorable. C'est ainsi qu'avec nos collaborateurs nous travaillons dans un climat de confiance et dans un esprit de solidarité. Pour ensemble accomplir de beaux projets et continuer à écrire l'histoire de notre entreprise et du Luxembourg.",
    titleGiorgetti: "Pourquoi travailler chez",
    titleBox1: "Leader du secteur",
    titleBox2: "Projets de grande ampleur",
    titleBox3: "Entreprise familiale",
    titleBox4: "Environnement sécurisé",
    titleBox5: "Équipements de qualité",
    titleBox6: "Installations neuves",
    titleBox7: "Perspectives d'embauche",
    textBox1: "Vous intégrez une entreprise leader dans son secteur, reconnue pour son expérience et l'excellence de son travail.",
    textBox2: "Vous participez à des projets de grande ampleur et contribuez au développement du Luxembourg.",
    textBox3: "Vous travaillez pour une entreprise familiale, aux valeurs fortes, dans une ambiance motivante et multiculturelle.",
    textBox4: "Votre santé et votre sécurité sont nos priorités, vous intervenez sur des chantiers organisés, propres et respectueux de l'environnement, certifiés des normes ISO 14001 et Superdreckskëscht.",
    textBox5: "Vous travaillez avec des équipements de qualité, des machines et camions dernière génération, régulièrement entretenus et contrôlés. Vous recevez des outils et matériels régulièrement renouvelés et adaptés à vos missions.",
    textBox6: "Vous êtes accueillis dans des installations de chantier neuves, nettoyées quotidiennement avec un équipement fonctionnel.",
    textBox7: "Vous bénéficiez d'un contrat longue durée avec des perspectives d'embauche. Chaque année, ce sont plus d'une cinquantaine de contrats SOFITEX qui évoluent en CDI chez Félix Giorgetti. La porte d'entrée vers une carrière valorisée au sein d'une grande entreprise pérenne.",
    titleSofitex: "Pourquoi choisir",
    titleBox8: "Proximité",
    titleBox9: "MySofitex",
    titleBox10: "Suivi administratif",
    textBox8: "Une équipe motivée, dynamique et à votre écoute qui vous accompagne tout au long de votre mission",
    textBox9: "Accédez à tous vos documents en un seul clic, demandez des acomptes et signez vos contrats électroniquement grâce à l'application MySofitex",
    textBox10: "De votre inscription à votre bulletin de paie, tout est géré par vos interlocuteurs au sein de l'agence Sofitex",
    offersBtn: "Consulter nos offres chez Félix Giorgetti",
    commentsTitle1: "Nos intérimaires partagent",
    commentsTitle2: "leur expérience chez",
    contactBoxTitle: "Contactez-nous",
    footerTitle1: "AGENCES SOFITEX LUXEMBOURG",
    footerTitle2: "RÉSEAU D'AGENCES D'INTÉRIM, TRAVAIL TEMPORAIRE, DE RECRUTEMENT ET DE GESTION DES RESSOURCES HUMAINES",
    footerJob: "RETROUVEZ NOS OFFRES D'EMPLOI SUR",
    footerText1: "De dimension internationale et forte d'une expérience de plus de 25 ans dans le recrutement et le travail temporaire, le réseau Sofitex a su gagner la confiance de nombreux clients en France, en Suisse, en Belgique en Allemagne et au Luxembourg.",
    footerText2: "SOFITEX EST PRÉSENT EN",
    footerText3: "DÉCOUVREZ NOS AGENCES MÉTIERS",
    footerPrivacy: "Politique de confidentialité",
    modalTitle: "Nos offres d'emplois",
    noOffers: "Aucune offre disponible pour l'instant",
    backHome: "Retour à l'accueil",
    errorMandatoryName: "Nom obligatoire",
    errorMandatoryFirstName: "Prénom obligatoire",
    errorInvalidEmail: "Adresse email invalide",
    errorMandatoryEmail: "Adresse email obligatoire",
    errorMandatoryTelephone: "Numéro de téléphone obligatoire",
    errorMandatoryCV: "CV obligatoire",
    errorMandatoryJob: "Veuillez choisir un poste",
    errorMandatoryRGPD: "Vous devez accepter les conditions générales d'utilisation pour envoyer votre candidature",
    reference: "Référence",
    location: "Localisation",
    domain: "Domaine d'activité",
    type: "Type de contrat",
    companyDescription: "Description de l'entreprise",
    jobDescription: "Description de l'offre",
    profile: "Profile recherché",
    applicationTitle: "Je dépose ma candidature",
    jobLabel: "Poste",
    jobPlaceholder: "Choisir un poste",
    nameLabel: "Nom",
    firstNameLabel: "Prénom",
    emailLabel: "Adresse email",
    phoneLabel: "Numéro de téléphone",
    cvLabel: "CV",
    rgpdText: "J'accepte les conditions générales d'utilisation de Sofitex ainsi que l'exploitation de mes données dans le strict cadre de ma recherche d'emploi.",
    rgpdLink: "en savoir plus",
    sendBtn: "Envoyer ma candidature",
    published: "publiée le",
    jan: "janvier", 
    fev: "février", 
    mar: "mars", 
    avr: "avril",
    mai: "mai",
    juin: "juin",
    juil: "juillet",
    aout: "août",
    sept: "septembre",
    oct: "octobre",
    nov: "novembre",
    dec: "décembre",
  },

  pt_BR: {
    //Modal Langues
    languageChoiceTitle: "Escolher uma língua",
    mainTitle: "Construamos juntos o futuro.",
    partnershipMenu: "Parceria Félix-Giorgetti - Sofitex",
    applyMenu: "Candidate-se",
    contactMenu: "Contacte-nos",
    otherOffersMenu: "Nossas outras ofertas na área de Esch-sur-Alzette",
    otherOffers: "Veja as nossas outras ofertas de trabalho no setor de Esch-sur-Alzette",
    hireFor: "recruta para",
    visuallyHidden1: "Nossas ofertas de emprego : ",
    visuallyHidden2: "Carpinteiros Cofragem M/F - ",
    visuallyHidden3: "Operador de Máquina M/F - ",
    visuallyHidden4: "Pedreiro H/M",
    text1: "Fiel à nossa história e às nossas raízes, Félix Giorgetti é uma grande família composta por mais de 450 membros, e quase 3.000 a nível do grupo. Estamos orgulhosos de ter tantos talentos entre nós, tornando-nos uma empresa conhecida pela qualidade do seu trabalho, pelo seu desempenho e pela sua capacidade de inovar.",
    text2: "Trabalhar connosco significa trabalhar com um actor importante na indústria de construção e imobiliária luxemburguesa. Os nossos empregados são os nossos bens mais valiosos. As suas qualificações e motivação são a base do nosso sucesso.",
    text3: "Sendo uma empresa com mais de cem anos, o emprego é fundamental e sempre nos empenhámos em preservá-lo, mesmo quando a situação económica não era favorável. É por isso que trabalhamos com os nossos empregados num clima de confiança e num espírito de solidariedade. Juntos, podemos realizar grandes projectos e continuar a escrever a história da nossa empresa e do Luxemburgo.",
    titleGiorgetti: "Porquê trabalhar na",
    titleBox1: "Líder do sector",
    titleBox2: "Projetos de grande envergadura",
    titleBox3: "Empresa familiar",
    titleBox4: "Ambiente seguro",
    titleBox5: "Equipamentos de qualidade",
    titleBox6: "Instalações novas",
    titleBox7: "Perspectivas de trabalho",
    textBox1: "Integrará uma empresa líder em seu setor, reconhecida pela sua experiência e a excelência do seu trabalho",
    textBox2: "Participará em projetos de grande envergadura e contribuírá para o desenvolvimento de Luxemburgo.",
    textBox3: "Trabalhará para uma empresa familiar, dotada de valores fortes, num ambiente motivante e multicultural",
    textBox4: "A sua saúde e segurança são as nossas prioridades, trabalhará em locais organizados, limpos e amigos do ambiente, certificados segundo as normas ISO 14001 e Superdreckskëscht, regularmente renovadas",
    textBox5: "Trabalhará com equipamentos de qualidade, as mais recentes máquinas e camiões, regularmente mantidos e controlados. Receberá regularmente ferramentas e materiais renovados e adaptados às suas missões",
    textBox6: "Serão recebidos em novas instalações, limpas diariamente com equipamento funcional",
    textBox7: "Beneficiará de um contrato a longo prazo com perspectivas de emprego. Todos os anos, mais de cinquenta contratos SOFITEX são convertidos em contratos permanentes na Félix Giorgetti. Esta é a porta de entrada para uma carreira valiosa dentro de uma empresa grande e bem sucedida.",
    titleSofitex: "Porquê escolher a",
    titleBox8: "Proximidade",
    titleBox9: "MySofitex",
    titleBox10: "Acompanhamento administrativo",
    textBox8: "Uma equipa motivada, dinâmica e à sua escuta, que o acompanha ao longo da sua missão",
    textBox9:  "Aceda a todos os seus documentos num só clique, solicite pagamentos antecipados e assine contratos electronicamente com a aplicação MySofitex",
    textBox10:  "Desde a sua inscrição até ao seu recibo de pagamento, tudo é gerido pelos seus contactos na Sofitex",
    offersBtn: "Veja as nossas ofertas na Félix Giorgetti",
    commentsTitle1: "O nosso pessoal temporário compartilha",
    commentsTitle2: "a sua experiência na",
    contactBoxTitle: "Contacte-nos",
    footerTitle1: "AGÊNCIAS SOFITEX NO LUXEMBURGO",
    footerTitle2: "REDE DE AGÊNCIAS DE TRABALHO TEMPORÁRIO, RECRUTAMENTO E GESTÃO DE RECURSOS HUMANOS",
    footerJob: "ENCONTRE AS NOSSAS OFERTAS DE EMPREGO NO",
    footerText1: "A rede Sofitex é internacional e tem mais de 25 anos de experiência em recrutamento e trabalho temporário, tendo ganho a confiança de numerosos clientes na França, Suíça, Bélgica, Alemanha e Luxemburgo.",
    footerText2: "SOFITEX ESTÁ PRESENTE",
    footerText3: "DESCUBRA AS NOSSAS AGÊNCIAS DO SECTOR TERCIÁRIO",
    footerPrivacy: "Política de confidencialidade",
    modalTitle: "Nossas ofertas de emprego",
    noOffers: "Nenhuma oferta de emprego no momento",
    backHome: "Voltar ao início",
    errorMandatoryName: "Apelido obrigatório",
    errorMandatoryFirstName: "Nome obrigatório",
    errorInvalidEmail: "Endereço e-mail inválido",
    errorMandatoryEmail: "Endereço e-mail obrigatório",
    errorMandatoryTelephone: "Número de telefone obrigatório",
    errorMandatoryCV: "CV obrigatório",
    errorMandatoryJob: "Por favor, escolha um posto",
    errorMandatoryRGPD: "Deve aceitar as condições gerais de utilização para enviar a sua candidatura",
    reference: "Referência",
    location: "Localização",
    domain: "Campo de atividade",
    type: "Tipo de contrato",
    companyDescription: "Descrição da empresa",
    jobDescription: "Descrição da oferta",
    profile: "Perfil procurado",
    applicationTitle: "Submito minha candidatura",
    jobLabel: "Posto",
    jobPlaceholder: "Escolher um posto",
    nameLabel: "Apelido",
    firstNameLabel: "Nome",
    emailLabel: "Endereço e-mail",
    phoneLabel: "Número de telefone",
    cvLabel: "CV",
    rgpdText: "Concordo com os termos e condições gerais da Sofitex e com a utilização dos meus dados para efeitos da minha pesquisa de emprego.",
    rgpdLink: "saber mais",
    sendBtn: "Enviar a minha candidatura",
    published: "Publicado em",
    jan: "janeiro", 
    fev: "fevereiro",
    mar: "março",
    avr: "abril",
    mai: "maio",
    juin: "junho",
    juil: "julho",
    aout: "agosto", 
    sept: "setembro",
    oct: "outubro",
    nov: "novembro",
    dec: "dezembro",

  },
    
};
  
export const LANG_NAMES = [
  { locale: 'fr_BE', name: 'FR' },
  { locale: 'pt_BR', name: 'PT' },
]