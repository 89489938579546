import React from 'react';
import { Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
 
// Toast success générique
const Container = (props) => <div>{props.children}</div>;

const Success = (message) => { 
    const toastContent = (
        <Container>
            <Text>{message}</Text>
        </Container>
    )

    return toast.success(toastContent)
}

export default Success;