import '../Global.css';
import React, { Component } from 'react';
import { Box, Grid, GridItem, Image, HStack, Flex, Button, Text, Center, Spinner, VisuallyHidden, Avatar, SimpleGrid, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody} from '@chakra-ui/react';
import { FaLinkedinIn, FaFacebookF} from 'react-icons/fa';
import { IoMail } from 'react-icons/io5';
import { BsTelephoneFill, BsGeoAltFill, BsSearch } from 'react-icons/bs';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Popup from "reactjs-popup";
import AxiosCenter from '../axios/AxiosCenter';
import { RiDoubleQuotesL } from 'react-icons/ri';
import TranslationContainer from '../languages/Translation/TranslationContainer';
import LangSwitchContainer from '../languages/LangSwitch/LangSwitchContainer';
import TokenService from '../axios/TokenService';
import { Link } from 'react-router-dom';
import Logo1 from '../images/logo_sofitex.svg';
import Logo2 from '../images/logo_giorgetti2.png';
import FlagPT from '../images/por-flag.png';
import FlagFR from '../images/fr-flag.png';
import FlagLU from '../images/lux-flag.png';
import FlagCH from '../images/ch-flag.png';
import FlagBE from '../images/flag-be.png';
import FlagAL from '../images/all-flag.png';
import Image1 from '../images/IMG_0080.jpg';
import Image2 from '../images/IMG_0553.jpg';
import Image3 from '../images/IMG_0859.JPG';
import Image4 from '../images/IMG_1306.JPG';
import Image5 from '../images/IMG_0538.JPG';
import Image6 from '../images/IMG_0691.JPG';
import Image7 from '../images/gio240.jpg';
import Image8 from '../images/GIO-logo.jpg';
import Image9 from '../images/avatar1.jfif';
import Image10 from '../images/avatar2.jfif';


export default class Index extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      offres: [],
      isLoaded: false,
      modalLanguage: false,
    };
  }

  handleResetForm() {
    this.setState({
      reinitialize: !this.state.reinitialize,
    })
  }

  // Ouverture du modal de contact
  handleToggle() {
    this.setState({
        modalIsOpen: true,
    })
  }

  // Fermeture du modal de contact
  handleClose() {
      this.setState({
          modalIsOpen: false,
      })
  }

  openModalLanguage() {
    this.setState({
      modalLanguage: true
    })
  }

  closeModalLanguage() {
    this.setState({
      modalLanguage: false
    })
  }

  componentDidMount() {
    window.scrollTo(0,0)
    Aos.init({duration: 2000, once: true})
    AxiosCenter.getListeOffres()
    .then((response) => {
      this.setState({
        offres: response.data,
        isLoaded: true,
      })
    })
    .catch((error) => {
      this.setState({
        isLoaded: true,
      })
    })
  }

  render() {

    const { isLoaded, modalIsOpen, offres, modalLanguage} = this.state;

    
    const Menu = ({ close }) => (
      <div className="menu">
        <ul onClick={close}>
          <a className='sofitex-menu-link' href="#felix-giorgetti">
            <li className='sofitex-menu-link'><TranslationContainer translationKey="partnershipMenu"/></li>
          </a>
          <li className='sofitex-menu-link' onClick={() => this.handleToggle()}><TranslationContainer translationKey="applyMenu"/></li>
          <a href="#contact">
            <li className='sofitex-menu-link'><TranslationContainer translationKey="contactMenu"/></li>
          </a>
          <li className='sofitex-menu-link' onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-sofitex-industrie-btp.html")}><TranslationContainer translationKey="otherOffersMenu"/></li>
        </ul>
      </div>
    );

    return (
      <Box width={'100vw'} bg={'white'}>
        <Flex width="100%" backgroundColor="#1d1d1d" position={'fixed'} top={0} zIndex={99}>
          <Flex width={{base:"100%", lg: "1024px", xl: "1250px"}} mx={'auto'}>
            <HStack height={{base: "54px", lg: "70px"}} pr={{base: 2, md: 4}} mr={'auto'}>
                <Center height="100%">
                  <Image ml={{base: 2, xl: 10}} src={Logo1} alt="logo mysofitex" width={{base: "90px", lg:"130px"}}/>
                </Center>
            </HStack>
            <Box display={{base: "none", md: "flex"}} pr={10}>
              <Flex className='sofitex-menu-link' as="button" alignItems="center">
                <Text className='font-sofitex' mr={2} ml={5} onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-sofitex-industrie-btp.html")}><TranslationContainer translationKey="otherOffers"/></Text>
                {/* <IoMdArrowRoundForward size="1.2em" /> */}
              </Flex>
            </Box>
            <Box as={'button'} mr={{base: 0, md: 4}} position={{base: 'absolute', md: 'unset'}} top={'9px'} right={'60px'} onClick={() => this.openModalLanguage()}>
              {TokenService.getLanguageToken() === 'pt_BR' ?
              <Flex alignItems={'center'} columnGap={1}>
                <Image width="37px" alt='' src={FlagPT} />
                <Text color={'white'} fontSize={'xs'}>português</Text>
              </Flex>
              :
              (!TokenService.getLanguageToken() || TokenService.getLanguageToken() === 'fr_BE') &&
              <Flex alignItems={'center'} columnGap={1}>
                <Image width="37px" alt='' src={FlagFR} />
                <Text color={'white'} fontSize={'xs'}>français</Text>
              </Flex>
            }
            </Box>
          </Flex>
        </Flex>
        <Box mt={{base: "54px", lg: "70px"}} height={{base: "500px", md:"900px"}} position="relative">
          <Center p={0} width="100%" height={{base: "500px", md:"900px"}} position="absolute" top={0} left={0} zIndex={97}>
            <Center className='dark-bg' height={'100%'} width={'100%'}>
              <Box pb={20}>
                <Text className='font-sofitex title' textAlign="center" fontWeight="bold" fontSize={{base: "4xl", md: "6xl"}} color={'white'}>Sofitex Esch-sur-Alzette BTP</Text>
                <Text className='font-sofitex title' my={3} textAlign="center" fontWeight="bold" fontSize={{base: "3xl", md: "5xl"}} color={'white'}><TranslationContainer translationKey="hireFor"/></Text>
                <Text className='font-maurer title' mb={{base: 10, md: 20}} textAlign="center" fontSize={{base: "4xl", md: "6xl"}} fontWeight="bold" color={'white'}>Félix Giorgetti</Text>
                <Flex justifyContent="center">
                  <Button className="button font-sofitex" rounded={'3xl'} size={{base: 'md', md: 'lg'}} fontSize={{base: 'lg', md: 'xl'}} width="250px" onClick={() => this.handleToggle()}><TranslationContainer translationKey="applyMenu"/></Button>
                </Flex>
              </Box>
            </Center>
          </Center>
          <Box className='bg-img1' height={{base: "500px", md:"900px"}} position={'relative'}>
            <Box as={'button'} position={'absolute'} bottom={{base: '-80px', md: '-130px', xl: '-200px'}} right={{base: 10, md: 20, xl: 40}} bg={'white'} p={5} zIndex={98} onClick={() => window.open('https://www.gio.lu/')}>
              <Image src={Logo2} alt="" minWidth={{base: '80px', md: '140px', xl: '240px'}} width={{base: '80px', md: '100px'}}/>
            </Box>
          </Box>
        </Box>
        <Box width={{base:"100%", lg: "1024px", xl: "1250px"}} mx={'auto'} pt={10} pb={20}>
          <VisuallyHidden>Félix Giorgetti - </VisuallyHidden>
          <VisuallyHidden><TranslationContainer translationKey="visuallyHidden1"/></VisuallyHidden>
          <VisuallyHidden><TranslationContainer translationKey="visuallyHidden2"/></VisuallyHidden>
          <VisuallyHidden><TranslationContainer translationKey="visuallyHidden3"/></VisuallyHidden>
          <VisuallyHidden><TranslationContainer translationKey="visuallyHidden4"/></VisuallyHidden>
          <Box id="felix-giorgetti">
            <Box pt={{base: 10, md: '120px', xl: '140px'}} pb={{base: 6, md: 10}} px={{base: 2, md: 8}} bg={'white'} rounded={'md'}>
              <Box mb={{base: 6, md: 10}}>
                <Flex mb={{base: 10, md: 10}} fontWeight="bold" width={'100%'} columnGap={4} textAlign={'center'} justifyContent={'center'} alignItems={'center'}>
                  {/* <Box display={{base: 'none', md: 'block'}} width={'240px'} borderBottom={'2px solid #002357'}></Box> */}
                  <Flex py={4} px={10} rowGap={2} flexDirection={{base: 'column', md: 'row'}} textAlign={'center'} justifyContent={'center'} alignItems={'center'} fontSize={{base: '3xl', md: '5xl'}}>
                    {/* <Box as="button" onClick={() => window.open('https://www.gio.lu/')}>
                      <Image src={'../images/logo_giorgetti2.png'} alt="" minWidth={{base: '80px', md: '100px'}} width={{base: '80px', md: '100px'}}/>
                    </Box> */}
                    <TranslationContainer translationKey="mainTitle"/>
                  </Flex>
                  {/* <Box display={{base: 'none', md: 'block'}} width={'240px'} borderBottom={'2px solid #002357'}></Box> */}
                </Flex>
                <Box px={{base: 2, md: 8}} fontSize="xl">
                  <Text id='trigger-img1' textAlign={{base: 'center', sm: 'left'}}><TranslationContainer translationKey="text1"/></Text>
                  <SimpleGrid my={10} columns={{base: 1, sm: 2}} columnGap={5}>
                    <Image alt='' src={Image1} data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-anchor="#trigger-img1" />
                    <Image alt='' src={Image2} data-aos="fade-left" data-aos-anchor-placement="bottom-bottom" data-aos-anchor="#trigger-img1" />
                  </SimpleGrid>
                  <Text id='trigger-img2' textAlign={{base: 'center', sm: 'left'}}><TranslationContainer translationKey="text2"/></Text>
                  <SimpleGrid my={10} columns={{base: 1, sm: 2}} columnGap={5}>
                    <Image alt='' src={Image3} data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-anchor="#trigger-img2" />
                    <Image alt='' src={Image4} data-aos="fade-left" data-aos-anchor-placement="bottom-bottom" data-aos-anchor="#trigger-img2" />
                  </SimpleGrid>
                  <Text id='trigger-img3' textAlign={{base: 'center', sm: 'left'}}><TranslationContainer translationKey="text3"/></Text>
                  <SimpleGrid mt={10} columns={{base: 1, sm: 2}} columnGap={5}>
                    <Image alt='' src={Image5} data-aos="fade-right" data-aos-anchor-placement="bottom-bottom" data-aos-anchor="#trigger-img3" />
                    <Image alt='' src={Image6} data-aos="fade-left" data-aos-anchor-placement="bottom-bottom" data-aos-anchor="#trigger-img3" />
                  </SimpleGrid>
                </Box>
              </Box>
              <Flex mt={{base: 10, md: 20}} mb={10} fontWeight="bold" columnGap={4} width={'100%'} textAlign={'center'} justifyContent={'center'} alignItems={'center'}>
                <Box display={{base: 'none', md: 'block'}} width={'210px'} borderBottom={'2px solid #002357'}></Box>
                <Flex py={4} px={4} columnGap={4} rowGap={2} flexWrap={'wrap'} textAlign={'center'} justifyContent={'center'} alignItems={'center'} color={'black'} fontSize={{base: '3xl', md: '5xl'}} border={'1px solid #002753'}>
                  <TranslationContainer translationKey="titleGiorgetti"/><Box as="span" color={'#002753'}> Félix&nbsp;Giorgetti</Box>
                </Flex>
                <Box display={{base: 'none', md: 'block'}} width={'210px'} borderBottom={'2px solid #002357'}></Box>
              </Flex>
              <Grid templateColumns="repeat(2, 1fr)" mt={{base: 10, md: 20}} px={{base: 4, md: 8}} columnGap={10} rowGap={{base: 6, md: 10}}>
                <GridItem className='shadow-sm' colSpan={{base: 2, lg: 1}} height={'100%'} justifyContent={'center'} alignItems={'center'} py={4} px={6} bg={'gray.50'} rounded={'md'}>
                  <Text className='font-sofitex' mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} color={'#002753'} fontSize={{base: '2xl', md: "3xl"}} fontWeight="bold">
                    <TranslationContainer translationKey="titleBox1"/>
                  </Text>
                  <Text className='font-sofitex' ml={{base: 0, md: 5}} mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} fontSize={'xl'}>
                    <TranslationContainer translationKey="textBox1"/>
                  </Text>
                </GridItem>
                <GridItem className='shadow-sm' colSpan={{base: 2, lg: 1}} height={'100%'} justifyContent={'center'} alignItems={'center'} py={4} px={6} bg={'gray.50'} rounded={'md'}>
                  <Text className='font-sofitex' mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} color={'#002753'} fontSize={{base: '2xl', md: "3xl"}} fontWeight="bold">
                    <TranslationContainer translationKey="titleBox2"/>
                  </Text>
                  <Text className='font-sofitex' ml={{base: 0, md: 5}} mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} fontSize={'xl'}>
                    <TranslationContainer translationKey="textBox2"/>
                  </Text>
                </GridItem>
                <GridItem className='shadow-sm' colSpan={{base: 2, lg: 1}} height={'100%'} justifyContent={'center'} alignItems={'center'} py={4} px={6} bg={'gray.50'} rounded={'md'}>
                  <Text className='font-sofitex' mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} color={'#002753'} fontSize={{base: '2xl', md: "3xl"}} fontWeight="bold">
                    <TranslationContainer translationKey="titleBox3"/>
                  </Text>
                  <Text className='font-sofitex' ml={{base: 0, md: 5}} mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} fontSize={'xl'}>
                    <TranslationContainer translationKey="textBox3"/>
                  </Text>
                </GridItem>
                <GridItem className='shadow-sm' colSpan={{base: 2, lg: 1}} height={'100%'} justifyContent={'center'} alignItems={'center'} py={4} px={6} bg={'gray.50'} rounded={'md'}>
                  <Text className='font-sofitex' mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} color={'#002753'} fontSize={{base: '2xl', md: "3xl"}} fontWeight="bold">
                    <TranslationContainer translationKey="titleBox4"/>
                  </Text>
                  <Text className='font-sofitex' ml={{base: 0, md: 5}} mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} fontSize={'xl'}>
                    <TranslationContainer translationKey="textBox4"/>
                  </Text>
                </GridItem>
                <GridItem className='shadow-sm' colSpan={{base: 2, lg: 1}} height={'100%'} justifyContent={'center'} alignItems={'center'} py={4} px={6} bg={'gray.50'} rounded={'md'}>
                  <Text className='font-sofitex' mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} color={'#002753'} fontSize={{base: '2xl', md: "3xl"}} fontWeight="bold">
                    <TranslationContainer translationKey="titleBox5"/>
                  </Text>
                  <Text className='font-sofitex' ml={{base: 0, md: 5}} mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} fontSize={'xl'}>
                    <TranslationContainer translationKey="textBox5"/>
                  </Text>
                </GridItem>
                <GridItem className='shadow-sm' colSpan={{base: 2, lg: 1}} height={'100%'} justifyContent={'center'} alignItems={'center'} py={4} px={6} bg={'gray.50'} rounded={'md'}>
                  <Text className='font-sofitex' mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} color={'#002753'} fontSize={{base: '2xl', md: "3xl"}} fontWeight="bold">
                    <TranslationContainer translationKey="titleBox6"/>
                  </Text>
                  <Text className='font-sofitex' ml={{base: 0, md: 5}} mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} fontSize={'xl'}>
                    <TranslationContainer translationKey="textBox6"/>
                  </Text>
                </GridItem>
                <GridItem className='shadow-sm' colSpan={{base: 2, lg: 1}} height={'100%'} justifyContent={'center'} alignItems={'center'} py={4} px={6} bg={'gray.50'} rounded={'md'}>
                  <Text className='font-sofitex' mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} color={'#002753'} fontSize={{base: '2xl', md: "3xl"}} fontWeight="bold">
                    <TranslationContainer translationKey="titleBox7"/>
                  </Text>
                  <Text className='font-sofitex' ml={{base: 0, md: 5}} mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} fontSize={'xl'}>
                    <TranslationContainer translationKey="textBox7"/>
                  </Text>
                </GridItem>
                <GridItem colSpan={{base: 2, lg: 1}} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <Box as={'button'} onClick={() => window.open('https://www.gio.lu/')}>
                    <Image src={Image7} alt="" minWidth={'240px'} width={'240px'}/>
                  </Box>
                </GridItem>
              </Grid>
            </Box>
            <Image src={Image8} alt='' hidden />
            <Box id="sofitex" mt={{base: 6, md: 10}}>
              <Box pt={{base: 6, md: 10}} pb={{base: 10, md: 10}} px={{base: 4, md: 8}} mb={{base: 6, md: 10}} bg={'white'} rounded={'md'}>
                <Flex mb={10} fontWeight="bold" columnGap={4} width={'100%'} textAlign={'center'} justifyContent={'center'} alignItems={'center'}>
                  <Box display={{base: 'none', md: 'block'}} width={'310px'} borderBottom={'2px solid #00ed00'}></Box>
                  <Flex py={4} px={4} columnGap={4} rowGap={2} flexWrap={'wrap'} textAlign={'center'} justifyContent={'center'} alignItems={'center'} color={'black'} fontSize={{base: '3xl', md: '5xl'}} border={'1px solid #00ed00'}>
                    <TranslationContainer translationKey="titleSofitex"/><Box as={'span'} color={'#00ed00'}> Sofitex</Box>
                  </Flex>
                  <Box display={{base: 'none', md: 'block'}} width={'310px'} borderBottom={'2px solid #00ed00'}></Box>
                </Flex>
                <Grid templateColumns="repeat(2, 1fr)" mt={{base: 10, md: 20}} pb={{md: 10}} px={{base: 2, md: 8}} columnGap={10} rowGap={{base: 6, md: 10}}>
                  <GridItem className={'shadow-sm'} colSpan={{base: 2, lg: 1}} height={'100%'} justifyContent={'center'} alignItems={'center'} py={4} px={6} bg={'gray.50'} rounded={'md'}>
                    <Text className='font-sofitex' mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} color={'#00ed00'} fontSize={{base: '2xl', md: "3xl"}} fontWeight="bold">
                      <TranslationContainer translationKey="titleBox8"/>
                    </Text>
                    <Text className='font-sofitex' ml={{base: 0, md: 5}} mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} fontSize={'xl'}>
                      <TranslationContainer translationKey="textBox8"/>
                    </Text>
                  </GridItem>
                  <GridItem className={'shadow-sm'} colSpan={{base: 2, lg: 1}} height={'100%'} justifyContent={'center'} alignItems={'center'} py={4} px={6} bg={'gray.50'} rounded={'md'}>
                    <Text className='font-sofitex' mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} color={'#00ed00'} fontSize={{base: '2xl', md: "3xl"}} fontWeight="bold">
                      <TranslationContainer translationKey="titleBox9"/>
                    </Text>
                    <Text className='font-sofitex' ml={{base: 0, md: 5}} mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} fontSize={'xl'}>
                      <TranslationContainer translationKey="textBox9"/>
                    </Text>
                  </GridItem>
                  <GridItem className={'shadow-sm'} colSpan={{base: 2, lg: 1}} height={'100%'} justifyContent={'center'} alignItems={'center'} py={4} px={6} bg={'gray.50'} rounded={'md'}>
                    <Text className='font-sofitex' mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} color={'#00ed00'} fontSize={{base: '2xl', md: "3xl"}} fontWeight="bold">
                      <TranslationContainer translationKey="titleBox10"/>
                    </Text>
                    <Text className='font-sofitex' ml={{base: 0, md: 5}} mt={{base: 2, md: 4}} mb={{base: 4, md: 2}} textAlign={'center'} fontSize={'xl'}>
                      <TranslationContainer translationKey="textBox10"/>
                    </Text>
                  </GridItem>
                  <GridItem colSpan={{base: 2, lg: 1}} display={'flex'} height={'100%'} justifyContent={'center'} alignItems={'center'} py={4} rounded={'md'}>
                    {/* <Button variant={'outline'} leftIcon={<BsSearch />} fontSize={{base: 'sm', sm: 'md'}} bg={'white'} borderColor={'#00ed00'} onClick={() => this.setState({modalIsOpen: true})}><TranslationContainer translationKey="offersBtn"/></Button> */}
                    <Button className="button font-sofitex" rounded={'3xl'} size={{base: 'md', md: 'lg'}} fontSize={{base: 'lg', md: 'xl'}} width="250px" onClick={() => this.handleToggle()}><TranslationContainer translationKey="applyMenu"/></Button>
                  </GridItem>
                </Grid>
                {/* <Box>
                  <Flex mt={{base: 10, md: 20}} fontWeight="bold" columnGap={4} width={'100%'} textAlign={'center'} justifyContent={'center'} alignItems={'center'}>
                    <Box display={{base: 'none', md: 'block'}} width={'120px'} borderBottom={'2px solid #002357'}></Box>
                    <Flex py={4} columnGap={4} rowGap={2} flexWrap={'wrap'} textAlign={'center'} justifyContent={'center'} alignItems={'center'} color={'black'} fontSize={{base: '3xl', md: '5xl'}} border={'1px solid #002357'}>
                      <TranslationContainer translationKey="commentsTitle1"/><Box as={'span'}> <TranslationContainer translationKey="commentsTitle2"/><Box as={'span'} color={'#002753'}> Félix&nbsp;Giorgetti</Box></Box>
                    </Flex>
                    <Box display={{base: 'none', md: 'block'}} width={'120px'} borderBottom={'2px solid #002357'}></Box>
                  </Flex>
                  <Grid templateColumns="repeat(12, 1fr)" columnGap={8} rowGap={{base: '100px', md: '100px'}} mt={{base: '100px', md: '140px'}}>
                    <GridItem pb={4} colSpan={{base: 12, sm: 6, lg: 4}} className='shadow' bg={'white'} rounded='lg'>
                      <Flex w={'100%'} justifyContent={'center'}>
                        <Box width={'128px'} height={'64px'} position={'relative'}>
                          <Avatar src={Image9} size={'2xl'} position={'absolute'} bottom={0} />
                        </Box>
                      </Flex>
                      <Text px={2} py={3} fontSize="xl" color={'gray.700'} fontWeight="bold" textAlign="center">Priscillia S.</Text>
                      <Flex justifyContent="center">
                        <RiDoubleQuotesL size="5em" color='#002357' />
                      </Flex>
                      <Box mt={2} px={3}>
                        <Box px={2} py={3} fontSize="lg" textAlign={'center'}>
                          <Box as={'cite'}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sed augue id libero malesuada pharetra nec fringilla ex. Proin eu tempus nulla, id congue arcu.
                          </Box>
                        </Box>
                      </Box>
                    </GridItem>
                    <GridItem pb={4} colSpan={{base: 12, sm: 6, lg: 4}} className='shadow' bg={'white'} rounded='lg'>
                      <Flex w={'100%'} justifyContent={'center'}>
                        <Box width={'128px'} height={'64px'} position={'relative'}>
                          <Avatar size={'2xl'} position={'absolute'} bottom={0} />
                        </Box>
                      </Flex>
                      <Text px={2} py={3} fontSize="xl" color={'gray.700'} fontWeight="bold" textAlign="center">Pierre P.</Text>
                      <Flex justifyContent="center">
                        <RiDoubleQuotesL size="5em" color='#002357' />
                      </Flex>
                      <Box mt={2} px={3}>
                        <Box px={2} py={3} fontSize="lg" textAlign={'center'}>
                          <Box as={'cite'}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sed augue id libero malesuada pharetra nec fringilla ex. Proin eu tempus nulla, id congue arcu.
                          </Box>
                        </Box>
                      </Box>
                    </GridItem>
                    <GridItem pb={4} colSpan={{base: 12, sm: 6, lg: 4}} className='shadow' bg={'white'} rounded='lg'>
                      <Flex w={'100%'} justifyContent={'center'}>
                        <Box width={'128px'} height={'64px'} position={'relative'}>
                          <Avatar src={Image10} size={'2xl'} position={'absolute'} bottom={0} />
                        </Box>
                      </Flex>
                      <Text px={2} py={3} fontSize="xl" color={'gray.700'} fontWeight="bold" textAlign="center">Hatim I.</Text>
                      <Flex justifyContent="center">
                        <RiDoubleQuotesL size="5em" color='#002357' />
                      </Flex>
                      <Box mt={2} px={3}>
                        <Box px={2} py={3} fontSize="lg" textAlign={'center'}>
                          <Box as={'cite'}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sed augue id libero malesuada pharetra nec fringilla ex. Proin eu tempus nulla, id congue arcu.
                          </Box>
                        </Box>
                      </Box>
                    </GridItem>
                  </Grid>
                </Box> */}
              </Box>
            </Box>
          </Box>
          {/* <Grid templateColumns="repeat(2, 1fr)" rowGap={20} mt={{base: 0, lg: 0}}>
            <GridItem colSpan={{base: 2, lg: 1}}> */}
              <Box id="contact" className='shadow-sm' py={6} px={{base: 4, md: 8}} mx={{base: 4, md: 8}} backgroundColor="gray.50" rounded={'lg'} border={'1px solid #edf2f7'}>
                <Text className='font-sofitex' fontWeight="bold" fontStyle="italic" fontSize="2xl" textAlign={{base: "center", lg: "start"}}><TranslationContainer translationKey="contactBoxTitle"/></Text>
                <Box className='font-sofitex' mt={{base: 2, md: 2}}>
                  <Text mb={2} fontSize="lg" fontWeight="bold">SOFITEX INTÉRIM ESCH-SUR-ALZETTE&nbsp;-&nbsp;BTP</Text>
                  <Flex mb={2} alignItems="center">
                    <BsGeoAltFill size="1.1em" />
                    <Flex flexWrap={'wrap'}>
                      <Text ml={2}>5, rue Luxembourg,</Text>
                      <Text ml={2}>L-4220 Esch-sur-Alzette</Text>
                    </Flex>
                  </Flex>
                  <Flex mb={2} alignItems="center">
                    <BsTelephoneFill />
                    <a className="sofitex-contact-link" href='tel:+352547633'><Text ml={2}>+352 (0)54 76 33</Text></a>
                  </Flex>
                  <Flex alignItems="center">
                    <IoMail size="1.1em" />
                    <a className="sofitex-contact-link" href='mailto:esch@sofitex.lu'><Text ml={2}>esch@sofitex.lu</Text></a>
                  </Flex>
                </Box>
              </Box>
            {/* </GridItem>
            <GridItem colSpan={{base: 2, lg: 1}} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Box as={'button'} onClick={() => window.open('https://www.gio.lu/')}>
                <Image src={'../images/logo_giorgetti.png'} alt="" minWidth={'240px'} width={'240px'}/>
              </Box>
            </GridItem>
          </Grid> */}
        </Box>
        <Grid templateColumns="repeat(12, 1fr)" mt={{base: 0, lg: 0}}>
        <GridItem colSpan={{base: 12, md: 8, lg: 9}} backgroundColor="#0a0a0a" py={{base: "40px", lg: "50px"}} px={{base: 2, md: 7}}>
          <Text className="font-sofitex" fontSize="lg" color="#00ed00" textAlign={{base:"center", md: "start"}}><TranslationContainer translationKey="footerTitle1"/></Text>
          <Text className="font-sofitex" fontSize="sm" color="#03b403" textAlign={{base:"center", md: "start"}}><TranslationContainer translationKey="footerTitle2"/></Text>
          <Grid templateColumns="repeat(6, 1fr)" mt={3}>
            <GridItem colSpan={{base: 3, lg: 2}}>
              <Flex justifyContent={{base:"center", md: "flex-start"}}>
                <Box as="button" className="sofitex-link" onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-sofitex-industrie-btp.html")}>Esch-sur-Alzette-BTP</Box>
              </Flex>
              <Flex justifyContent={{base:"center", md: "flex-start"}}>
                <Box as="button" className="sofitex-link" onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-esch-sur-alzette-industrie.html")}>Esch-sur-Alzette</Box>
              </Flex>
              <Box width="100%" display={{base: "block", lg: "none"}}>
                <Flex justifyContent={{base:"center", md: "flex-start"}}>
                  <Box as="button" className="sofitex-link" onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-sofitex-wiltz.html")}>Wiltz</Box>
                </Flex>
              </Box>
            </GridItem>
            <GridItem colSpan={{base: 3, lg: 2}}>
              <Flex justifyContent={{base:"center", md: "flex-start"}}>
                <Box as="button" className="sofitex-link" onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-sofitex-tertiaire-banque-assurance-luxembourg.html")}>Luxembourg-Ville</Box>
              </Flex>
              <Flex justifyContent={{base:"center", md: "flex-start"}}>
                <Box as="button" className="sofitex-link" textAlign={{base: "center", md: "start"}} onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-sofitex-talent.html")}>Talent - Finance, Office, IT</Box>
              </Flex>
            </GridItem>
            <GridItem display={{base: "none", lg: "block"}} colSpan={2}>
              <Flex justifyContent={{base:"center", md: "flex-start"}}>
                <Box as="button" className="sofitex-link" onClick={() => window.open("https://www.sofitex.lu/FR/agence-interim-travail-temporaire/agence-interim-sofitex-wiltz.html")}>Wiltz</Box>
              </Flex>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem colSpan={{base: 12, md: 4, lg: 3}} px={2} backgroundColor="#00ed00">
          <Flex height="100%" alignItems="center" py={{base: "40px", lg: 0}}>
            <Box width="100%">
              <Text className="font-sofitex" mb={2} textAlign="center" fontWeight="bold"><TranslationContainer translationKey="footerJob"/>&nbsp;:</Text>
              <Flex justifyContent="center" mt={2}>
                <Box as="button" mr={3} onClick={() => window.open("https://www.facebook.com/sofitex.luxembourg")}>
                  <FaFacebookF size="1.8em" />
                </Box>
                <Box as="button" pt={1} onClick={() => window.open("https://www.linkedin.com/company/sofitex-luxembourg")}>
                  <FaLinkedinIn size="2.1em" />
                </Box>
              </Flex>
            </Box>
          </Flex>
        </GridItem>
      </Grid>
      <Box maxWidth="100vw" py={{base: "30px", md: "50px"}} px={{base: 2, md: "20%"}} backgroundColor="#2f2f2f">
        <Box display={{base: "none", lg: "block"}}>
          <Text className="font-sofitex" mb={4} textAlign="center" fontSize="sm" color="#b5b5b5">
            <TranslationContainer translationKey="footerText1"/>
          </Text>
        </Box>
        <Text mr={2} textAlign="center" color="white"><TranslationContainer translationKey="footerText2"/>&nbsp;:</Text>
        <Flex mt={1} flexFlow="row wrap" justifyContent="center">
          <Flex>
            <Image width="25px" alt='' src={FlagFR} />
            <Text className="sofitex-link font-sofitex" as="button" ml="2px" mr={2} color="white" onClick={() => window.open("https://www.sofitex.fr/")}>France</Text>
          </Flex>
          <Flex>
            <Image width="25px" alt='' src={FlagLU} />
            <Text className="sofitex-link font-sofitex" as="button" ml="2px" mr={2} color="white" onClick={() => window.open("https://www.sofitex.lu/")}>Luxembourg</Text>
          </Flex>
          <Flex alignItems={'center'}>
            <Image width="25px" alt='' height="15px" src={FlagBE} />
            <Text className="sofitex-link font-sofitex" as="button" ml="2px" mr={2} color="white" onClick={() => window.open("https://www.sofitex.be/")}>Belgique</Text>
          </Flex>
          <Flex>
            <Image width="25px" alt='' src={FlagCH} />
            <Text className="sofitex-link font-sofitex" as="button" ml="2px" mr={2} color="white" onClick={() => window.open("https://www.sofitex.ch/")}>Suisse</Text>
          </Flex>
          <Flex>
            <Image width="25px" alt='' src={FlagAL} />
            <Text className="sofitex-link font-sofitex" as="button" ml="2px" color="white" onClick={() => window.open("https://www.sofitex-zeitarbeit.de/")}>Allemagne</Text>
          </Flex>
        </Flex>
        <Flex mt={3} flexFlow="row wrap" justifyContent="center" alignItems="center">
          <Text className="font-sofitex" mt={1} textAlign="center" color="white"><TranslationContainer translationKey="footerText3"/>&nbsp;:</Text>
          <Flex mt={1}>
            <Text className="sofitex-link font-sofitex" as="button" ml="2px" fontWeight="bold" color="white" onClick={() => window.open("https://www.sofitex-talent.lu/")}>Sofitex Talent</Text>
          </Flex>
        </Flex>
        <Flex justifyContent="center">
          <Text className="sofitex-link font-sofitex" mt={5} textAlign="center" as="button" color="white" onClick={() => window.open("https://www.sofitex.lu/FR/politique-de-confidentialite.html")}><TranslationContainer translationKey="footerPrivacy"/></Text>
        </Flex>
      </Box>

      <Popup
        modal
        overlayStyle={{ background: "#1d1d1d" }}
        closeOnDocumentClick={false}
        trigger={(open) => <Box position={'fixed'} display={{base: "block", md: "none"}} className={open ? "burger-menu open" : "burger-menu"} >
          <div className="bar1"/>
          <div className="bar2"/>
          <div className="bar3"/>
        </Box>}
      >
        {(close) => <Menu close={close} />}
      </Popup>

        <Modal isOpen={modalIsOpen} blockScrollOnMount closeOnOverlayClick size="md" isCentered onClose={() => this.handleClose()}>
            <ModalOverlay />
                <ModalContent>
                    <ModalHeader backgroundColor="#edf2f7" roundedTop="md" borderBottom="1px solid lightgray">
                        <Text className='font-sofitex' fontSize={{base: "2xl", md: "3xl"}} fontWeight="bold"><TranslationContainer translationKey="modalTitle"/></Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    {!isLoaded ?
                    <Center my={4}>
                        <Spinner color='#00ed00' />
                    </Center>
                    :
                    offres.length === 0 ?
                    <Center my={4}>
                        <Text className='font-sofitex' fontSize="lg" fontWeight="bold" fontStyle="italic"><TranslationContainer translationKey="noOffers"/></Text>
                    </Center>
                    :
                    offres.map((offre, index) => {
                        return (
                        <Flex key={index} justifyContent="center">
                            <Link to={"/detail?id="+offre.id}>
                            <Box as="button" className='font-sofitex offers-btn' wordBreak={'normal'} p={3} my={2} rounded="2xl" fontSize="xl">{offre.title}</Box>
                            </Link>
                        </Flex>
                        )
                    })}
                    </ModalBody>
                </ModalContent>
        </Modal>
        {/* Modal choix de la langue */}
        <Modal isOpen={modalLanguage} blockScrollOnMount closeOnOverlayClick size="xs" isCentered onClose={() => this.closeModalLanguage()}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader backgroundColor="#edf2f7" roundedTop="md" borderBottom="1px solid lightgray">
                <Text className="font-style" fontSize="2xl">
                    <TranslationContainer translationKey="languageChoiceTitle"/>
                </Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody onClick={() => this.closeModalLanguage()} py={4}>
                    <LangSwitchContainer />
                </ModalBody>
            </ModalContent>
        </Modal>
      </Box>
    );
  }
}